// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/featureCard/featureCard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/featureCard/featureCard.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import { motion } from 'framer-motion';
import { fadeIn } from '~/lib/variants';
const FeatureCard = ({
  img,
  title,
  description,
  logo
}) => {
  return <motion.div variants={fadeIn('up', 0.4, 1.3)} viewport={{
    once: true
  }} initial="hidden" whileInView="show" exit="hidden" className="w-full bg-slate-900/40 backdrop-blur-sm rounded-lg sm:w-[calc(50%-1.5rem)] md:w-[calc(33%-1.5rem)] flex flex-col items-center justify-center px-6 py-4 lg:px-8 lg:py-6">
      <div className="w-full h-52 flex items-center justify-center">
        <img src={img} alt={title} className="h-full w-auto object-contain select-none" loading="lazy" />
      </div>

      <div className="flex flex-col">
        <h4 className="flex items-center mb-2 font-semibold text-2xl tracking-tight gap-2 line-clamp-2">
          <div className="rounded-full h-10 min-w-[40px] w-10 bg-primary/30 text-foreground flex items-center justify-center">
            {logo}
          </div>
          <span className="w-auto line-clamp-2 text-primary">{title}</span>
        </h4>
        <p className="text-sm font-normal tracking-tight px-4 ">{description}</p>
      </div>
    </motion.div>;
};
_c = FeatureCard;
export default FeatureCard;
var _c;
$RefreshReg$(_c, "FeatureCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;