// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1730642741826.035";
}
// REMIX HMR END

import { motion } from "framer-motion";
import { fadeIn } from "~/lib/variants";
import { BoomBox, Gamepad2, Laptop, Smartphone, Tv2 } from "lucide-react";
import FeatureCard from "~/components/featureCard/featureCard";
import { Table, TableBody, TableCell, TableRow } from "~/components/ui/table";
export const meta = () => {
  const schemaMarkup = [{
    "@context": "http://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [{
      "@type": "ListItem",
      position: 1,
      name: "خانه",
      item: "https://xraybox.net/"
    },
    // {
    //   "@type": "ListItem",
    //   position: 2,
    //   name: "درباره ما",
    //   item: "https://xraybox.net/about",
    // },
    {
      "@type": "ListItem",
      position: 3,
      name: "نصب و راه اندازی",
      item: "https://xraybox.net/initilize"
    }, {
      "@type": "ListItem",
      position: 4,
      name: "عیب یابی",
      item: "https://xraybox.net/diagnose"
    }, {
      "@type": "ListItem",
      position: 5,
      name: "خرید و شارژ",
      item: "https://xraybox.net/charge"
    }, {
      "@type": "ListItem",
      position: 6,
      name: "پشتیبانی",
      item: "https://xraybox.net/support"
    }]
  }, {
    "@context": "http://schema.org/",
    "@type": "Corporation",
    name: "پرتادکس",
    logo: "https://pertadex.ir/assets/img/logo2.png",
    url: "https://pertadex.ir",
    address: {
      "@type": "PostalAddress",
      addressCountry: "Iran, Islamic Republic of"
    },
    sameAs: ["https://www.instagram.com/XRay.Global/", "https://t.me/XRayIRSupport"]
  }];
  return [{
    title: "دسترسی به اینترنت آزاد با XRayBOX"
  }, {
    name: "description",
    content: `X XRay یک دستگاه VPN Router است که دسترسی به اینترنت آزاد و بدون
      محدودیت را از طریق وای فای LAN برای کاربر مقدور میسازد کلیه تنظیمات
      دستگاه BOX XRay توسط شرکت ارائه دهنده انجام شده وآماده کار به دست
      کاربر میرسد پشتیبانی و بروز رسانی این دستگاه مادامی که سرویس روتر
      توسط کاربر تمدید گردد به عهده شرکت ارائه کننده میباشد.
      `
  }, {
    name: "robots",
    content: "index, follow"
  }, {
    name: "og:image",
    content: "/assets/img/xraybox.png"
  }, {
    property: "business:contact_data:country_name",
    content: "Iran, Islamic Republic of"
  }, {
    "script:ld+json": schemaMarkup
  }];
};
export default function Index() {
  return <div className="h-full">
      {/* initial animation */}
      <motion.div initial={{
      display: "flex"
    }} transition={{
      duration: 0.1,
      delay: 2.2
    }} animate={{
      display: "none"
    }} className="fixed z-10 left-0 right-0 bottom-0 top-0 h-screen w-full flex flex-col bg-black container md:flex-row justify-center items-center gap-y-4">
        {/* <motion.img
          initial={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 1.5 }}
          animate={{ opacity: 0, scale: 0 }}
          src="/assets/img/xrayglobal.png"
          alt="xray global"
          className=" mx-auto w-full h-auto"
         /> */}
        <motion.img initial={{
        opacity: 1,
        scale: 1
      }} transition={{
        duration: 0.8,
        delay: 1.5
      }} animate={{
        opacity: 0,
        scale: 0
      }} src="/assets/img/xraybox.png" alt="xray box" className=" mx-auto w-96 h-auto" />
      </motion.div>

      {/* section 1 */}
      <section className="w-full md:w-[90vw] m-auto flex items-center pt-28 py-12 gap-y-8 md:pt-40 justify-center flex-col-reverse px-4 md:flex-row">
        <div className="md:w-1/2">
          <motion.h1 variants={fadeIn("left", 2.3, 1.3)} initial="hidden" animate="show" exit="hidden" className="px-1 pb-3 text-4xl md:text-5xl font-bold tracking-tight transition-colors">
            درباره{" "}
            <strong className="text-purple-600 drop-shadow-[0_2px_2px_rgba(157,0,255,0.45)]">
              XRayBOX
            </strong>
          </motion.h1>
          <motion.h4 className="leading-7 px-3 shadow text-lg" variants={fadeIn("left", 2.5, 1.3)} initial="hidden" animate="show" exit="hidden">
            XRay Box یک دستگاه VPN Router است که به نوعی سخت افزار VPN محسوب
            میشود و دسترسی به اینترنت آزاد و بدون محدودیت را از طریق وای فای و
            یا کابل شبکه برای کاربر مقدور میسازد. <br /> این دستگاه صرفا روتر
            بوده و اینترنت ندارد و برای استفاده از آن میبایست با کابل به مودم
            وصل شده تا به سرورهای مجموعه متصل گردد. <br /> کلیه تنظیمات این
            دستگاه انجام شده و کاملا آماده به دست کاربر میرسد. <br /> پشتیبانی و
            بروز رسانی این دستگاه مادامی که سرویس آن توسط کاربر تمدید گردد بر
            عهده این مجموعه میباشد.
          </motion.h4>
        </div>
        <div className="w-full md:w-1/2 relative flex justify-center items-center flex-col">
          <div className="w-full relative flex justify-start px-4 items-center ">
            <motion.img variants={fadeIn("down", 2.3, 1.3)} initial="hidden" animate="show" exit="hidden" src="/assets/img/router.png" alt="xraybox router" className="object-contain drop-shadow-[0_20px_20px_rgba(157,0,255,0.65)] w-7/12 select-none" />
            <motion.img variants={fadeIn("right", 2.3, 1.3)} initial="hidden" animate="show" exit="hidden" src="/assets/img/xraybox-box.png" alt="xraybox router" className="object-contain absolute -z-10 left-0 drop-shadow-[0_20px_20px_rgba(157,0,255,0.65)] w-8/12 select-none " />
            <motion.img variants={fadeIn("left", 2.5, 1.3)} initial="hidden" animate="show" exit="hidden" src="/assets/img/40-offer.png" alt="xraybox router" style={{
            rotate: "0deg"
          }} className="object-contain absolute z-10 left-10 sm:left-16 sm:-top-28 -top-20 2xl:left-24 2xl:-top-36 w-4/12 select-none" />
          </div>
          <motion.h2 variants={fadeIn("right", 0.7, 1.3)} initial="hidden" animate="show" whileInView="show" viewport={{
          once: true
        }} className="text-red-600 mt-4 relative w-fit after:absolute after:w-full after:h-[3px] 
          after:bg-yellow-600 after:right-0 after:rotate-3 after:top-[11px] font-bold text-2xl md:text-3xl">
            4,500,000 تومان
          </motion.h2>
          <motion.h2 variants={fadeIn("left", 0.3, 1.3)} initial="hidden" whileInView="show" viewport={{
          once: true
        }} className="px-1 pb-3 text-green-500 text-2xl text-center md:text-3xl font-bold tracking-tight transition-colors">
            2,750,000 تومان
          </motion.h2>
        </div>
      </section>

      <section className="flex w-full items-center justify-center flex-col py-12">
        <motion.h2 variants={fadeIn("left", 0.3, 1.3)} initial="hidden" whileInView="show" viewport={{
        once: true
      }} className="px-1 pb-3 pt-8 text-violet-500 text-2xl text-center md:text-3xl font-bold tracking-tight transition-colors">
          مشخصات فنی
        </motion.h2>
        <Table className="px-8 mx-auto pt-4 flex flex-col justify-start md:justify-center md:flex-row">
          <TableBody className="divide-x h-full">
            <TableRow className="">
              <TableCell className="bg-slate-900/30">کارخانه سازنده</TableCell>
              <TableCell className="bg-slate-700/30">Xiaomi</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">
                طراحی و پشتیبانی
              </TableCell>
              <TableCell className="bg-slate-700/30">XRay Global</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">پروتکل شبکه</TableCell>
              <TableCell className="bg-slate-700/30">
                IEEE 802.11b/g/n و IEEE 802.3/3u
              </TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">
                RAM <br />
                ROM
              </TableCell>
              <TableCell className="bg-slate-700/30 text-end" dir="ltr">
                64 MB DDR2 <br />
                16MB NorFlash
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody className="divide-x h-full">
            <TableRow className="">
              <TableCell className="bg-slate-900/30">آنتن</TableCell>
              <TableCell className="bg-slate-700/30 text-end" dir="ltr">
                4 High Gain 5dBi
              </TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">قابلیت پشتیبانی</TableCell>
              <TableCell className="bg-slate-700/30">تا ۶۴ دستگاه</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">
                سرعت انتقال داده ها
              </TableCell>
              <TableCell className="bg-slate-700/30">300Mbps</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">
                پوشش دهی وای فای
              </TableCell>
              <TableCell className="bg-slate-700/30">
                در مکان بدون مانع (دیوار) تا ۱۰۰ متر <br /> و در صورت وجود مانع
                تا ۴۰۰ متر
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody className="divide-x h-full">
            <TableRow className="">
              <TableCell className="bg-slate-900/30">سرعت کارت شبکه</TableCell>
              <TableCell className="bg-slate-700/30">100Mbps</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">تعداد پورت شبکه</TableCell>
              <TableCell className="bg-slate-700/30">(LAN) دو عدد</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">تعداد پورت شبکه</TableCell>
              <TableCell className="bg-slate-700/30">
                تعداد پورت شبکه (WAN) یک عدد
              </TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell className="bg-slate-900/30">
                وزن <br /> ابعاد
              </TableCell>
              <TableCell className="bg-slate-700/30">
                ۲۶۰ گرم <br />
                ۲۵×۱۰۷×۱۹۵ میلی‌متر
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </section>

      {/* section 2 */}
      <section className="w-full pt-20 md:pt-36">
        <motion.h2 variants={fadeIn("down", 0.2, 1.3)} viewport={{
        once: true
      }} initial="hidden" whileInView="show" exit="hidden" className=" px-1 py-3 text-3xl md:text-4xl text-center font-bold tracking-tight transition-colors">
          کاربرد های
          <strong className="text-purple-600 drop-shadow-[0_2px_2px_rgba(157,0,255,0.45)]">
            {" "}
            XRayBOX
          </strong>
        </motion.h2>
        <motion.p variants={fadeIn("down", 0.4, 1.3)} viewport={{
        once: true
      }} initial="hidden" whileInView="show" exit="hidden" className="text-lg text-center px-4">
          تمامی دستگاههایی که از طریق وای فای و یا کابل شبکه امکان دسترسی به
          اینترنت را دارند میتوانند با استفاده از این روتر به اینترنت آزاد وصل
          شوند.
        </motion.p>
        <div className="w-full flex flex-col sm:flex-row flex-wrap items-center justify-center gap-x-4 gap-y-3 px-8 md:px-12 lg:px-16 pt-12">
          <FeatureCard logo={<Smartphone className="h-6 w-6" />} img="/assets/img/feature/smartphones.png" title="موبایل هوشمند" description="هر نوع موبایل با هر برندی" />
          <FeatureCard img="/assets/img/feature/pc-laptop.png" logo={<Laptop className="h-6 w-6" />} title="رایانه" description="تمامی رایانه ها با هر برند و هر نوع سیستم عاملی" />
          <FeatureCard img="/assets/img/feature/androidbox.png" logo={<BoomBox className="h-6 w-6" />} title="اندروید باکس و اپل تیوی" description="امکان استفاده از تمامی مدل های اندروید باکس و اپل تیوی" />
          <FeatureCard img="/assets/img/feature/game-console.png" logo={<Gamepad2 className="h-6 w-6" />} title="کنسول های بازی" description="انواع مدل ها و کنسول های بازی Play Station ، Xbox و Nintendo" />
          <FeatureCard img="/assets/img/feature/smart-tv.png" logo={<Tv2 className="h-6 w-6" />} title="تلویزیون هوشمند" description="استفاده از تمامی برنامه های موجود بر روی تلویزیون های هوشمند مانند Youtube و Netflix" />
        </div>
      </section>
    </div>;
}
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;